<template>
	<div>
		<v-btn
			color="blue"
			class="white--text ma-2"
			@click="openModal"
		>   
			Edytuj
		</v-btn>

		<v-dialog v-model="modal" max-width="500">  
			<v-card class="pa-4">
				<v-card-text>
					<v-text-field
						v-model="name"
						label="Imię i nazwisko"
						clearable
						:error-messages="nameErrors"
						@input="$v.name.$touch()"
						@blur="$v.name.$touch()"
					></v-text-field>
					<v-text-field
						v-model="email"
						label="Email"
						clearable
						:error-messages="emailErrors"
						@input="$v.email.$touch()"
						@blur="$v.email.$touch()"
					></v-text-field>
					<v-text-field
						v-model="phone"
						label="Numer telefonu"
						clearable
						:error-messages="phoneErrors"
						@input="$v.phone.$touch()"
						@blur="$v.phone.$touch()"
					></v-text-field>
					<v-combobox
						v-model="langsToEdit"
						:items="langsArray"
						label="Języki"
						multiple
						chips
					></v-combobox>
				</v-card-text>
				<v-card-actions class="ml-4">
					<v-btn
						color="primary"
						@click="udpate"
						:disabled="$v.$invalid"
						:loading="isLoaded"
					>
						Edytuj 
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>
import { validationMixin } from 'vuelidate'
const { required, email, decimal} = require('vuelidate/lib/validators')
import { errorMsg } from '@/mixins/Validation';

export default {
	name: 'EditUser',
    mixins: [validationMixin, errorMsg],
	data(){
        return{
			type: null,
			isLoaded: false,
            name: null,
            email:  null,
            nip: null,
            phone: null,
            account: null,
			langsToEdit: null,
            modal: false,
            id: null,
			langsArray: ["PL", "EN", "DE", "FR", "IT", "CS", "DA,", "ES", "PT", "RU", "SK", "SV", "UK", "HU", "ZH", "HR", "NL", "NB"],
        }
	},
	computed: {
        getUser(){
            return this.$store.getters.getUser;
        },
        getID(){
            return this.$store.getters.getUserID
        }
    },
	validations: {
        name: {
            required,
        },
        email: {
            required,
            email
        },
        phone: {
            decimal
        },
    },
	methods: {
		udpate(){
			this.isLoaded = true;
            console.log(`${this.url}api/apprentice`);
            this.$axios.put(`${this.url}api/apprentice`, {
                id: this.getID ,
                accountNumber: this.account,
                password: this.getUser.password,
                role: this.getUser.role.toLowerCase(),
                type: this.type,
                email: this.email,
                payment: this.getUser.payment,
                phone: this.phone,
                langs: this.langsToEdit,
                pesel: this.nip,
                name: this.name,
			})
				.then(()=> {
					this.isLoaded = false;
					this.modal = false;
					this.$store.commit('updateUser', 
						{
							name:this.name,
							avatar: this.getUser.avatar ,
							role: this.getUser.role,
							phone: this.phone,
							payment: this.getUser.payment,
							pesel: this.nip,
							email: this.email,
							accountNumber: this.account,
							langs: this.langsToEdit,
							type: this.type,
							_id: this.getID
						})
				})
        },
		openModal(){
			this.modal = !this.modal;
			this.type = this.getUser.type
            this.name = this.getUser.name
            this.email = this.getUser.email
            this.nip = this.getUser.pesel
            this.phone = this.getUser.phone
            this.account = this.getUser.accountNumber
            this.langsToEdit = this.getUser.langs
        },
	}
}
</script>