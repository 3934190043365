<template>
    <div>
        <MyAccount />
    </div>
</template>

<script>
import MyAccount from '@/components/templates/MyAccount.vue';

export default {
    components: {
        MyAccount
    }
}
</script>